<template>
  <div>
    <b-row>
      <b-col>
        <h5>Production Details</h5>
      </b-col>
      <b-col class="text-right">
        <span v-b-toggle.cu-production data-cy="editProduction">
          <feather-icon icon="EditIcon" class="text-success" size="20"/>
        </span>
      </b-col>
      <b-sidebar
        ref="editProduction"
        id="cu-production"
        right
        backdrop
        shadow
        width="450px"
        sidebar-class="sidebar-lg"
        bg-variant="white"
      >
        <template #header="{ hide }">
          <div class="d-flex justify-content-between align-items-center content-sidebar-header w-100">
            <h5 class="mb-0">Add / Edit Production</h5>
            <div>
              <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
            </div>
          </div>
        </template>
        <modal-edit-production v-if="curProduction" :prod-to-edit="curProduction"></modal-edit-production>
      </b-sidebar>
    </b-row>
    <b-row class="align-items-left ">
      <b-col md="12 ">
        <b-card no-body class="overflow-hidden p-1">
          <b-row>
            <b-col md="2" sm="4" class="mb-2 mb-sm-0">
              <b-card-img :src="curProductionCover" alt="Image" class="rounded-0"></b-card-img>
            </b-col>
            <b-col md="10" sm="10">
              <b-card-body class="mt-0 p-0" :title="curProductionName">
                <b-card-text>
                  {{ curProductionDesc }}
                </b-card-text>
                <hr class="qf-separator" />
                <b-row>
                  <b-col>
                    <h3 class="mb-1">Production Basics</h3>

                    <div v-if="curProduction && curProduction.attributes.productionType" md="4" cols="6" class="my-05">
                      <h5>Production Type</h5>
                      <span>{{ curProduction.attributes.productionType }}</span>
                    </div>

                    <div v-if="curProduction && curProduction.attributes.genres" md="4" cols="6" class="my-05">
                      <h5>Genres</h5>
                      <span>{{ curProduction.attributes.genres.join(', ') }}</span>
                    </div>
                  </b-col>
                  <b-col>
                    <h3 class="mb-1">Services</h3>

                    <div v-if="curProduction && curProduction.attributes.serviceCompany" md="4" cols="6" class="my-05">
                      <h5>Service Company</h5>
                      <span>{{ curProduction.attributes.serviceCompany }}</span>
                    </div>

                    <div v-if="curProduction && curProduction.attributes.contactEmail" md="4" cols="6" class="my-05">
                      <h5>Contact E-Mail</h5>
                      <span>{{ curProduction.attributes.contactEmail }}</span>
                    </div>

                    <div v-if="curProduction && curProduction.attributes.contactPhone" md="4" cols="6" class="my-05">
                      <h5>Contact Phone</h5>
                      <span>{{ curProduction.attributes.contactPhone }}</span>
                    </div>
                  </b-col>

                  <b-col>
                    <h3 class="mb-1">Creative Team</h3>

                    <div v-if="curProduction && curProduction.attributes.director" md="4" cols="6" class="my-05">
                      <h5>Director</h5>
                      <span>{{ curProduction.attributes.director }}</span>
                    </div>

                    <div v-if="curProduction && curProduction.attributes.studio" md="4" cols="6" class="my-05">
                      <h5>Studio</h5>
                      <span>{{ curProduction.attributes.studio }}</span>
                    </div>

                    <div v-if="curProduction && curProduction.attributes.producer" md="4" cols="6" class="my-05">
                      <h5>Producer</h5>
                      <span>{{ curProduction.attributes.producer }}</span>
                    </div>

                    <div v-if="curProduction && curProduction.attributes.writer" md="4" cols="6" class="my-05">
                      <h5>Writer</h5>
                      <span>{{ curProduction.attributes.writer }}</span>
                    </div>

                    <div v-if="curProduction && curProduction.attributes.castingDirector" md="4" cols="6" class="my-05">
                      <h5>Casting Director</h5>
                      <span>{{curProduction.attributes.castingDirector }}</span>
                    </div>

                    <div v-if="curProduction && curProduction.attributes.executiveProducer" md="4" cols="6" class="my-05">
                      <h5>Executive Producer</h5>
                      <span>{{ curProduction.attributes.executiveProducer }}</span>
                    </div>

                    <div v-if="curProduction && curProduction.attributes.showrunner" md="4" cols="6" class="my-05">
                      <h5>Showrunner</h5>
                      <span>{{ curProduction.attributes.showrunner }}</span>
                    </div>
                  </b-col>

                  <b-col>
                    <h3 class="mb-1">Distribution</h3>
                    <div v-if="curProduction && curProduction.attributes.platform" md="4" cols="6" class="my-05">
                      <h5>Platform</h5>
                      <span>{{ curProduction.attributes.platform }}</span>
                    </div>
                    <div v-if="curProduction && curProduction.attributes.studio" md="4" cols="6" class="my-05">
                      <h5>Production Studio</h5>
                      <span>{{ curProduction.attributes.studio }}</span>
                    </div>
                    <div v-if="curProduction && curProduction.attributes.distributor" md="4" cols="6" class="my-05">
                      <h5>Distributor</h5>
                      <span>{{ curProduction.attributes.distributor }}</span>
                    </div>
                    <div v-if="curProduction && curProduction.attributes.broadcaster" md="4" cols="6" class="my-05">
                      <h5>Broadcaster</h5>
                      <span>{{ curProduction.attributes.broadcaster }}</span>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCardImg,
  BCol,
  BRow,
  BCardBody,
  BSidebar,
  VBToggle,
  // BAvatarGroup,
  // BAvatar,
} from 'bootstrap-vue';
import store from '@/store';
import ModalEditProduction from '@/modals/ModalEditProduction';

// import ModalAddEditShootingLocation from "@/modals/ModalAddEditShootingLocation";
import Parse from 'parse';
import moment from 'moment';

export default {
  components: {
    BCard,
    BCardText,
    BCardImg,
    BCol,
    BRow,
    BCardBody,
    BSidebar,
    ModalEditProduction,
    // BAvatarGroup,
    // BAvatar,
    // ModalAddEditShootingLocation,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      activeProduction: {
        attributes: {
          name: '',
        },
      },
    };
  },
  methods: {
    prodCoverUrl(production) {
      let productionImg = this.defaultLogo('png');
      if (production.attributes.coverImage) {
        productionImg = production.attributes.coverImage._url;
      }
      return productionImg;
    },
    mapLink(lat, long) {
      return (
        'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + long
      );
    },
    getSootingLocations(roleId) {
      let sootingLocation = Parse.Object.extend('ShootingLocation');
      let LocationQuery = new Parse.Query(sootingLocation);

      const rolePointer = {"__type": "Pointer", "className": "ActingRole", "objectId": roleId};

      LocationQuery.equalTo('relatedRole', rolePointer);
      LocationQuery.find()
        .then(function(results) {
          store.commit('app/SET_LOCATIONS', results);
          //console.log("Processed locations results: ", results);
        })
        .catch(function(err) {
          console.log('Parse error', err.message);
        });
    },
    niceDate(parseDate) {
      return moment(parseDate).format('DD-MM-YYYY');
    },
  },
  computed: {
    curProduction() {
      return store.getters['app/currentProduction'];
    },
    curProductionCover() {
      if (this.activeProduction) {
        return this.prodCoverUrl(this.activeProduction);
      } else {
        return this.defaultLogo('png');
      }
    },
    curProductionName() {
      if (this.activeProduction) {
        return this.activeProduction.attributes.name;
      } else {
        return '';
      }
    },
    curProductionDesc() {
      if (this.activeProduction) {
        return this.activeProduction.attributes.synopsis;
      } else {
        return '';
      }
    },
    shootingLocations() {
      return store.getters['app/shootingLocations'];
    },
  },
  mounted() {
    store.commit('app/CLEAR_ROLE');
    this.getSootingLocations(this.roleId);
  },
  watch: {
    curProduction: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          let prodQuery = new Parse.Query(Parse.Object.extend('Production'));
          prodQuery.get(this.$route.params.prodId).then(function(theProduction){
              store.commit('app/SET_PRODUCTION', theProduction);
            });
        }
        this.activeProduction = newVal;
      },
    },
  },
};
</script>

<style>
hr.qf-separator {
  width: 100%;
  height: 1px;
  display: block;
  background: rgba(255, 255, 255, 0.4);
}

.b-sidebar-header {
  background-color: #000013;
}

.shooting-location,
.qf-sides {
  padding: 0.5rem 0;
}
.shooting-location + .shooting-location,
.qf-sides + .qf-sides {
  border-top: 1px solid #3b4253;
}
.shooting-location svg {
  margin-top: 3px;
}
</style>
