<template>
    <div class="h-100 p-relative">
        <div class="qf-search-filters">
            <div class="card p-2 m-0">
                <validation-observer :ref="`productionValidation` + prodId">
                    <b-form-group label="Production Basics" class="pb-1">
                        <b-row>
                            <b-col>
                                <label for="productionName" class="pt-1"
                                >Production name <sup class="text-danger">*</sup></label
                                >
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="production name"
                                    rules="required"
                                >
                                    <b-form-input
                                        name="name"
                                        id="productionName"
                                        v-model="form.name"
                                        placeholder="Your production name"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <label for="prodCoverFile" class="pt-1">Production cover image</label>
                                <b-form-file
                                    id="prodCoverFile"
                                    v-model="form.newCover"
                                    accept="image/*"/>

                                <div v-if="productionCover">
                                    <div class="qf-cover-preview pt-1">
                                        <b-img :src="productionCover"></b-img>
                                    </div>
                                    <b-button class="my-1" size="sm" variant="success" @click="removeImage">
                                        Remove Image
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <label for="productionType" class="pt-1"
                                >Production Type<sup class="text-danger">*</sup></label
                                >
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="production type"
                                    rules="required"
                                >
                                    <b-form-select
                                        id="productionType"
                                        name="productionType"
                                        v-model="form.productionType"
                                        :options="formOptions.productionTypes"
                                    >
                                        <template #first>
                                            <b-form-select-option value="null" disabled
                                            >Pick one
                                            </b-form-select-option
                                            >
                                        </template>
                                    </b-form-select>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <label for="prodGenres" class="pt-1">Genres</label>
                                <Select2
                                    id="prodGenres"
                                    class="mb-1"
                                    v-model="form.genres"
                                    :options="remapOptions(formOptions.genres)"
                                    :settings="{multiple: true}"
                                />
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <label for="productionDescription" >Synopsis</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-textarea
                                        name="synopsis"
                                        v-model="form.synopsis"
                                        id="productionDescription"
                                        placeholder="Short description of the plot of the film"
                                        rows="3"
                                    />
                                </div>
                            </b-col>
                        </b-row>

                    </b-form-group>
                    <b-form-group label="Companies">
                        <b-row>
                            <b-col>
                                <div>
                                    <label for="billingCompany" class="pt-1"
                                    >Billing Company</label>
                                    <b-form-select
                                        id="billingCompany"
                                        name="billingCompany"
                                        :options="billingCompanies"
                                        v-model="form.billingCompanyId"
                                    >
                                        <template #first>
                                            <b-form-select-option value="null" disabled
                                            >Pick one
                                            </b-form-select-option
                                            >
                                        </template>
                                    </b-form-select>
                                    <small class="text-primary" @click="setCompanyToEdit({})" v-b-toggle.cu-company data-cy="addCompanyButton">
                                        <u>Click here to setup a billing company.</u>
                                    </small>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <label for="serviceCompany" class="pt-1">Service company</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                        name="serviceCompany"
                                        id="serviceCompany"
                                        v-model="form.serviceCompany"
                                        placeholder=""
                                    />
                                </div>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <label for="serviceCompEmail" class="pt-1">Contact E-mail</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                        name="serviceCompEmail"
                                        id="serviceCompEmail"
                                        v-model="form.contactEmail"
                                        placeholder=""
                                    />
                                </div>
                            </b-col>
                            <b-col>
                                <label for="serviceCompPhone" class="pt-1">Contact Phone</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                        name="serviceCompPhone"
                                        id="serviceCompPhone"
                                        v-model="form.contactPhone"
                                        placeholder=""
                                    />
                                </div>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <label for="serviceCompAddress" class="pt-1">Contact Address</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-textarea
                                        name="serviceCompAddress"
                                        v-model="form.serviceCompAddress"
                                        id="serviceCompAddress"
                                        placeholder="Enter the full address of the service company"
                                        rows="3"
                                    />
                                </div>
                            </b-col>
                        </b-row>

                    </b-form-group>

                    <b-form-group label="Creative Team" class="pb-1">

                        <b-row>
                            <b-col>
                                <label for="prodDirector" class="pt-1">Director</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                            name="director"
                                            id="prodDirector"
                                            v-model="form.director"
                                            placeholder=""
                                    />
                                </div>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col>
                                <label for="prodProducer" class="pt-1">Producer</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                            name="producer"
                                            id="prodProducer"
                                            v-model="form.producer"
                                            placeholder=""
                                    />
                                </div>
                            </b-col>
                        </b-row>
    
                        <b-row>
                            <b-col>
                                <label for="prodWriter" class="pt-1">Writer</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                            name="writer"
                                            id="prodWriter"
                                            v-model="form.writer"
                                            placeholder=""
                                    />
                                </div>
                            </b-col>
                            <b-col>
                                <label for="prodAgency" class="pt-1">Agency</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                            name="agency"
                                            id="prodAgency"
                                            v-model="form.agency"
                                            placeholder=""
                                    />
                                </div>
                            </b-col>
                        </b-row>
    
                        <b-row>
                            <b-col>
                                <label for="prodCastingDirector" class="pt-1">Casting director</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                            name="castingDirector"
                                            id="prodCastingDirector"
                                            v-model="form.castingDirector"
                                            placeholder=""
                                    />
                                </div>
                            </b-col>
                            <b-col>
                                <label for="execProducer" class="pt-1">Executive Producer</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                            name="execproducer"
                                            id="execProducer"
                                            v-model="form.executiveProducer"
                                            placeholder=""
                                    />
                                </div>
                            </b-col>
                        </b-row>
    
                        <label for="showRunner" class="pt-1">Show runner</label>
                        <div class="d-flex justify-content-lg-between align-items-center">
                            <b-form-input
                                    name="showrunner"
                                    id="showRunner"
                                    v-model="form.showrunner"
                                    placeholder=""
                            />
                        </div>
                    </b-form-group>

                    <b-form-group label="Distribution" class="pb-1">
                        <b-row>
                            <b-col>
                                <label for="prodStudio" class="pt-1">Production studio</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                        name="studio"
                                        id="prodStudio"
                                        v-model="form.studio"
                                        placeholder=""
                                    />
                                </div>
                            </b-col>
                            <b-col>
                                <label for="prodPlatform" class="pt-1">Platform</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                        name="platform"
                                        id="prodPlatform"
                                        v-model="form.platform"
                                        placeholder=""
                                    />
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label for="prodBroadcaster" class="pt-1">Broadcaster</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                        name="broadcaster"
                                        id="prodBroadcaster"
                                        v-model="form.broadcaster"
                                        placeholder=""
                                    />
                                </div>
                            </b-col>

                            <b-col>
                                <label for="prodDistributor" class="pt-1">Distributor</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input
                                        name="distributor"
                                        id="prodDistributor"
                                        v-model="form.distributor"
                                        placeholder=""
                                    />
                                </div>
                            </b-col>

                        </b-row>

                    </b-form-group>
                </validation-observer>
                <b-button variant="primary" class="mb-1" @click="submitProduction()">{{ saveLabel }}</b-button>
                <b-button variant="outline-primary" class="mb-1" @click="cancelModal()"
                >CANCEL
                </b-button>
                <b-sidebar
                        ref="mySidebar"
                        id="cu-company"
                        right
                        backdrop
                        shadow
                        width="450px"
            
                        sidebar-class="sidebar-lg"
                        bg-variant="white"
                >
                    <template #header="{ hide }">
                        <div class="d-flex justify-content-between align-items-center content-sidebar-header w-100">
                            <h5 class="mb-0">Add/Edit Company</h5>
                            <div>
                                <feather-icon
                                        class="ml-1 cursor-pointer"
                                        icon="XIcon"
                                        size="16"
                                        @click="hide"
                                />
                            </div>
                        </div>
                    </template>
                    <modal-edit-company v-if="selCompany && modalMode === 'editCompany'" :company-to-edit="selCompany"></modal-edit-company>
                </b-sidebar>
            </div>
        </div>
    </div>
</template>

<script>
import ModalEditCompany from "@/modals/ModalEditCompany";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {required, email} from "@validations";
import {
    BRow,
    BCol,
    //BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    //BFormCheckbox,
    BButton,
    //BBadge,
    BFormTextarea,
    //BAvatar,
    BImg, BSidebar, VBToggle, VBModal
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Parse from "parse";
import store from "@/store";
//import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import Ripple from "vue-ripple-directive";
import Select2 from "v-select2-component";


export default {
    name: "ModalEditProduction",
    components: {
        BRow,
        BCol,
        BSidebar,
        ModalEditCompany,
        //BForm,
        BFormFile,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormSelectOption,
        //BFormCheckbox,
        BButton,
        //BBadge,
        BFormTextarea,
        ValidationProvider,
        ValidationObserver,
        //VueBootstrapTypeahead,
        //BAvatar,
        BImg,
        Select2
    },

    directives: {
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple,
    },
    props: {
        prodToEdit: {
            type: Object,
            required: false,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            modalMode: '',
            selCompany: {},
            required,
            email,
            formOptions: {
                genres: [],
                productionTypes: [],
            },

            form: {
                name: "",
                synopsis: "",
                studio: "",
                platform: "",
                agency: "",
                executiveProducer: "",
                showrunner: "",
                isArchived: false,
                teamAdmins: [],
                teamMembers: [],
                productionType: "",
                billingCompany: "",
                billingCompanyId: "",
                genres: [],
                genreSelection: "",
                newCover: null,
                serviceCompany: '',
                contactEmail: '',
                contactPhone: '',
            },
            prodId: null,
            skillSuggest: [],
            filteredOptions: [],
            limit: 10,
            skillSelected: null,
            previewImgSrc: false,
            imgToBeRemoved: false,
        };
    },

    mounted() {
        this.formOptions.genres = store.getters["dict/productionGenres"];
        this.formOptions.productionTypes = store.getters["dict/productionTypes"];

        if (this.prodToEdit && this.prodToEdit.attributes) {
            this.form = JSON.parse(JSON.stringify(this.prodToEdit.attributes));
            if (this.form.billingCompany) {
                this.form.billingCompanyId = this.form.billingCompany.objectId;
            } else {
                this.form.billingCompanyId = null;
            }
            this.prodId = this.prodToEdit.id;
        }
        let currentUser = Parse.User.current();
        if (currentUser) {
            this.currentUser = currentUser;
            this.curUserId = currentUser.id;

            const BCompany = Parse.Object.extend("BillingCompany");
            const query = new Parse.Query(BCompany);

            const userPointer = {
                __type: "Pointer",
                className: "_User",
                objectId: this.currentUser.id,
            };

            query.equalTo("owner", userPointer);

            query
                .find()
                .then(function (results) {
                    store.commit("app/SET_COMPANIES", results);
                })
                .catch(function (exception) {
                    console.log(exception.message);
                });
        }
        this.previewImgSrc = this.prodCoverUrl();
    },
    computed: {
        companies(){
            return store.getters['app/allCompanies'];
        },
        billingCompanies() {
            let companies = store.getters["app/allCompanies"];

            let prodCompanies = [];
            if (companies) {
                companies.forEach(function (prodCompany) {
                    prodCompanies.push({
                        value: prodCompany.id,
                        text: prodCompany.attributes.companyName,
                    });
                });
            }

            return prodCompanies;
        },
        saveLabel() {
            if (this.prodToEdit.id) {
                return "SAVE CHANGES";
            } else {
                return "CREATE PRODUCTION";
            }
        },
        productionCover() {
            if (this.form.newCover) {
                let selectedFile = this.form.newCover;
                return URL.createObjectURL(selectedFile);
            } else if (this.previewImgSrc) {
                return this.previewImgSrc;
            } else {
                return false;
            }
        },
        
    },
    methods: {
        remapOptions(options){
            if(options){
                options.forEach(function(oneOption, index){
                    options[index] = {id: oneOption.value, value: oneOption.value, text: oneOption.text}
                });
            }
            return options;
        },
        prodCoverUrl() {
            let productionImg = false;
            if (this.prodToEdit && this.prodToEdit.attributes && this.prodToEdit.attributes.coverImage) {
                productionImg = this.prodToEdit.attributes.coverImage._url;
            }
            return productionImg;
        },
        setCompanyToEdit(companyObj){
            this.selCompany = companyObj;
            this.modalMode = 'editCompany';
        },

       
        badgeText(genreKey) {
            let entry = this.formOptions.genres.find((o) => o.value === genreKey);
            if (entry) {
                return entry.text;
            } else {
                return "";
            }
        },
        selectHandler(selection) {
            //console.log("Selection: ", this.form.genreSelection);
            this.form.genres.push(selection.value);
            this.$refs.genrePicker.inputValue = "";
        },
        removeGenre(index) {
            this.form.genres.splice(index, 1);
            //console.log(this.form.genres);
        },
        cancelModal() {
            this.$root.$emit("bv::toggle::collapse", "cu-production");
        },
        submitProduction() {
            let self = this;
            this.$refs[`productionValidation` + this.prodId]
                .validate()
                .then((success) => {
                    if (success) {
                        console.log("success");
                        self.saveProduction();
                    }
                })
                .catch(function (err) {
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Check all fields for valid data!",
                            icon: "EditIcon",
                            variant: "danger",
                        },
                    });
                    console.log("validation errors", err);
                });
        },

        removeImage() {
            //this.imgToBeRemoved = false;
            this.prodToEdit.unset("coverImage");
            this.previewImgSrc = null;
        },


        saveProduction() {
          console.log('=====================')
            let self = this;
            let prodObj = Parse.Object.extend("Production");
            let Production = new prodObj();
            let curUser = Parse.User.current();

            //const userPointer = {"__type": "Pointer", "className": "_User", "objectId": this.curUserId};

            if (this.prodId) {
                Production.set("objectId", this.prodId);
            } else {
                Production.add("teamMembers", curUser.id);
                Production.add("teamAdmins", curUser.id);
            }

            Production.set("name", this.form.name);
            Production.set("productionType", this.form.productionType);

            Production.set("synopsis", this.form.synopsis);

            Production.set("serviceCompany", this.form.serviceCompany);
            Production.set("contactEmail", this.form.contactEmail);
            Production.set("contactPhone", this.form.contactPhone);

            Production.set("director", this.form.director);
            Production.set("writer", this.form.writer);
            Production.set("producer", this.form.producer);
            Production.set("castingDirector", this.form.castingDirector);

            Production.set("studio", this.form.studio);
            Production.set("platform", this.form.platform);
            Production.set("agency", this.form.agency);
            Production.set("executiveProducer", this.form.executiveProducer);
            Production.set("showrunner", this.form.showrunner);

            Production.set("broadcaster", this.form.broadcaster);
            Production.set("distributor", this.form.distributor);
            Production.set("genres", this.form.genres);

            Production.set("isArchived", this.form.isArchived);
            if (this.imgToBeRemoved) {
                Production.set("coverImage", null);
            }

            if (this.form.newCover) {
                let coverImg = this.form.newCover;
                let parseFile = new Parse.File(coverImg.name, coverImg, coverImg.type);
                Production.set("coverImage", parseFile);
            }
            if(this.form.billingCompanyId){
              const companyPointer = {
                __type: "Pointer",
                className: "BillingCompany",
                objectId: this.form.billingCompanyId,
              };
              Production.set("billingCompany", companyPointer);
            }

            Production.save()
                .then(function (newProd) {
                    console.log("New production:", newProd);
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Production saved!",
                            icon: "EditIcon",
                            variant: "success",
                        },
                    });
                    self.imgToBeRemoved = false;

                    if (!self.prodId) {
                        store.commit("app/SET_ADD_PRODUCTION", newProd);
                    } else {
                        store.commit("app/UPDATE_PRODUCTIONS", newProd);
                    }

                    store.commit("app/SET_PRODUCTION", newProd);

                    self.previewImgSrc = self.prodCoverUrl();

                    self.cancelModal();
                    // location.reload();
                })
                .catch(function (err) {
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: err.message,
                            icon: "CloseIcon",
                            variant: "danger",
                        },
                    });
                    console.log("Save error: ", err.message);
                });
        },
    },
    watch: {
        prodToEdit: {
            immediate: true,
            handler(newProdToEdit) {
                if (newProdToEdit && newProdToEdit.attributes) {
                    this.prodId = newProdToEdit.id;
                    this.previewImgSrc = this.prodCoverUrl();
                    this.form = JSON.parse(JSON.stringify(newProdToEdit.attributes));
                } else {
                    this.prodId = null;
                    this.previewImgSrc = null;
                    this.form = {
                        name: "",
                        synopsis: "",
                        studio: "",
                        platform: "",
                        agency: "",
                        executiveProducer: "",
                        showrunner: "",
                        isArchived: false,
                        teamAdmins: [],
                        teamMembers: [],
                        productionType: "",
                        billingCompany: "",
                        billingCompanyId: "",
                        genres: [],
                        genreSelection: "",
                        newCover: null,
                    }
                }
                if (this.form.billingCompany) {
                    this.form.billingCompanyId = this.form.billingCompany.objectId;
                } else {
                    this.form.billingCompanyId = null;
                }
            }
        },
        
//        prodToEdit(newVal) {
//            this.form = JSON.parse(JSON.stringify(newVal));
//            if (this.form.billingCompany) {
//                this.form.billingCompanyId = this.form.billingCompany.objectId;
//            } else {
//                this.form.billingCompanyId = null;
//            }
//        },

        companies: {
            immediate: true,
            deep: true,
            handler(newVal){
                if (!newVal) {
                    let compQuery = new Parse.Query(Parse.Object.extend('BillingCompany'));
                    compQuery.get(this.$route.params.companyId).then(function (theCompany) {
                        store.commit('app/SET_COMPANIES', theCompany);
                    });
                }
            }
        },
    },
};
</script>

<style scoped>
.qf-cover-preview {
    width: 50%;
}

.qf-cover-preview img {
    width: 100%;
}
</style>
